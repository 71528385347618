import React, {FC, useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import qs from 'qs';
import {AxiosResponse} from 'axios';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {
  ClaimSideFilters,
  setClaimsFilter,
  setClaimsOrder,
  setClaimsPagination,
  setClaimsSearch,
} from '../../app/claimsReducer';
import {useAppDispatch, useAppSelector} from '../../app/store';
import {
  selectProfileRoles,
  selectLoggedInStaffCode,
  selectProfileBuyerDepartments,
} from '../../app/selectors';
import {api, get} from '../../utils/Request';
import {formatDate} from '../../utils/DateUtils';
import {getDisplayAmountValue} from '../../utils/AmountUtils';
import getClaimFrequency from '../../utils/ClaimFrequencyUtils';
import {createOrderFromParams} from '../../utils/common';
import type {
  ClaimBulkUploadResponse,
  ClaimListItemViewModel,
  ClaimListResponse,
  Order,
  Pagination,
  TableColumn,
} from '../../types';
import {AdvanceButton} from '../Button';
import {ClaimStatus} from '../Status';
import {SearchInputField} from '../SearchInputField';
import {TableTabPanel} from '../TableTabPanel';
import {PageTabs} from '../PageTabs';
import {Amount} from '../Amount';
import RequireAuth from '../Auth/RequireAuth';
import {SimpleDataTable, AdditionalLineMode} from '../SimpleDataTable';
import ListFilterContainer from '../ListFilterContainer';
import FiltersIndicator from '../FiltersIndicator';
import ClaimExpandedView from './ClaimExpandedView';
import ClaimAdvanceModal from './ClaimAdvanceModal';
import InvoiceReviewModal from './InvoiceReviewModal';
import ClaimSideFilter from './ClaimSideFilters';
import BulkUploadRebateClaims from './BulkUploadRebateClaims/BulkUploadRebateClaims';
import DownloadClaimsCsvButton from './DownloadClaimsCsvButton';
import BulkUploadCompleteModal from './BulkUploadCompleteModal';
import RollbackStatus from './RollbackStatus';
import {
  mapGetClaimsParams,
  getGSTType,
  showRowSelect,
  displayClaimType,
  type GetClaimsOptions,
} from './claimListingUtils';

const PREFIX = 'ClaimListing';

const classes = {
  outlined: `${PREFIX}-outlined`,
  disabled: `${PREFIX}-disabled`,
  root: `${PREFIX}-root`,
  containerRoot: `${PREFIX}-containerRoot`,
  containerWidthLg: `${PREFIX}-containerWidthLg`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const Root = styled('div')(({theme}) => ({
  [`&. ${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.containerRoot}`]: {
    paddingLeft: '3.1875rem',
    paddingRight: '2.6875rem',
  },

  [`& .${classes.containerWidthLg}`]: {
    maxWidth: '125rem',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '3.125rem 0',
  },
}));

const BlockRounded = styled(BlockRoundedIcon)`
  padding: 0.5rem;
`;

const AdjustmentVendor = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  div:first-of-type {
    font-weight: bold;
  }
`;

const TotalClaimAmountContainer = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
`;

const TotalClaimAmount = styled('div')`
  white-space: nowrap;
  color: rgba(112, 112, 112, 1);
  font-style: italic;
`;

const DescriptionColumn = styled('div')`
  word-break: break-word;
`;

type Props = {
  my?: boolean;
};

const ClaimListing: FC<Props> = ({my = false}) => {
  const roles = useAppSelector(selectProfileRoles);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const buyerDepartments = useAppSelector(selectProfileBuyerDepartments);
  const {filterSelection, pagination, searchText, order} = useAppSelector((state) => state.claims);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<ClaimListItemViewModel[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [expandedClaims, setExpandedClaims] = useState<any>({});
  const [openAdvanceModal, setOpenAdvanceModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openSideFilters, setOpenSideFilters] = useState(false);
  const [bulkUploadSuccessModal, setBulkUploadSuccessModal] = useState<{
    show: boolean;
    count: number;
  }>({show: false, count: 0});
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);

  const filterRef = useRef<HTMLDivElement>(null);

  const setFilterSelection = (newFilters: ClaimSideFilters) => {
    dispatch(setClaimsFilter(newFilters));
  };

  const setPagination = (pagination: Partial<Pagination>) => {
    dispatch(setClaimsPagination(pagination));
  };

  const setSearchText = (searchText: string | null) => {
    dispatch(setClaimsSearch(searchText));
  };

  const setOrder = (newOrder: Order) => {
    dispatch(setClaimsOrder(newOrder));
  };

  // The get claims endpoint returns only deal claims where user permission is ManageDealClaims only
  const setData = useCallback(
    (responseData: ClaimListResponse) => {
      const data = responseData.data;
      setRows(data);
      setLoading(false);
      dispatch(
        setClaimsPagination({
          totalPages: responseData.totalPages,
          currentPage: responseData.currentPage,
          pageSize: responseData.pageSize,
          totalCount: responseData.totalCount,
        })
      );
    },
    [dispatch]
  );

  const getClaims = useCallback(
    async (params: GetClaimsOptions, singleOrderBy?: boolean) => {
      setLoading(true);
      setSelectedRows([]);
      try {
        const response: AxiosResponse<ClaimListResponse> = await get(api(Consts.Api.Claims), {
          params: {
            entityCode: config.entityCode,
            ownedByStaffCode: my ? loggedInStaffCode : null,
            ...params,
          },
          paramsSerializer: (params: GetClaimsOptions) => {
            const {orderBy, ...rest} = params;
            const nextParams =
              singleOrderBy || !isDefaultOrder
                ? params
                : {...rest, orderBy: [orderBy, 'description:asc']};
            const result = qs.stringify(nextParams, {skipNulls: true, arrayFormat: 'repeat'});
            return result;
          },
        });
        setData(response.data);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        setLoading(false);
      }
    },
    [setData, loggedInStaffCode, isDefaultOrder, my]
  );

  useEffect(() => {
    let {departments, ...filterSelectionWithoutDepartments} = filterSelection;

    if (buyerDepartments && buyerDepartments.length > 0) {
      setFilterSelection({departments: buyerDepartments, ...filterSelectionWithoutDepartments});
      departments = buyerDepartments;
    }

    const request = mapGetClaimsParams(
      {
        searchText,
        departments: departments,
        ...pagination,
        ...filterSelectionWithoutDepartments,
        ...order,
      },
      my ? loggedInStaffCode : undefined
    );
    getClaims(request);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ensure that selectedFinalisedClaims is either all finalised claims or empty
  const selectedFinalisedClaims = useMemo(() => {
    const finalisedClaimIds = rows
      .filter((row) => row.status === 'Finalised')
      .map((claim) => claim.id);
    const selectedFinalisedClaims = selectedRows.filter((row) => finalisedClaimIds.includes(row));
    if (selectedFinalisedClaims.length === selectedRows.length) {
      return selectedFinalisedClaims;
    }
    if (selectedFinalisedClaims.length < selectedRows.length) {
      return [];
    }
    return [];
  }, [selectedRows, rows]);

  const displayRelatedId = (rowData: ClaimListItemViewModel) => {
    switch (rowData.agreementType) {
      case Consts.AgreementTypeEnum.Deal:
        return rowData.agreementId;
      case Consts.AgreementTypeEnum.ContractAgreement:
        return rowData.rebateId;
      default:
        return '';
    }
  };

  const renderActionColumn = (rowData: ClaimListItemViewModel) => {
    if (Boolean(rowData.parentClaimId)) {
      return null;
    }

    const getTypeDetails = () => {
      if (rowData.agreementType === Consts.AgreementTypeEnum.ContractAgreement) {
        return {
          routerPath: Consts.RouterPath.RebateSummary.replace(':id', `${rowData.rebateId}`),
          label: 'Open Rebate',
        };
      } else if (rowData.agreementSubType === Consts.AgreementSubTypeEnum.Spiv) {
        return {
          routerPath: Consts.RouterPath.SPIVSummary.replace(':id', `${rowData.agreementId}`),
          label: 'Open SPIV',
        };
      } else if (rowData.agreementSubType === Consts.AgreementSubTypeEnum.MixAndMatch) {
        return {
          routerPath: Consts.RouterPath.MixAndMatchSummary.replace(
            ':id',
            `${rowData.mixAndMatchId}`
          ),
          label: 'Open Mix & Match',
        };
      } else {
        return {
          routerPath: Consts.RouterPath.DealSummary.replace(':id', `${rowData.agreementId}`),
          label: 'Open Deal',
        };
      }
    };

    const {routerPath, label} = getTypeDetails();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      window.open(routerPath, '_blank');
      e.stopPropagation();
    };

    return (
      <Button
        sx={{
          lineHeight: 'normal',
          borderRadius: '0.625rem',
          padding: '0 0.5rem',
          whiteSpace: 'unset',
          fontSize: '0.875rem',
        }}
        variant="outlined"
        onClick={handleClick}
      >
        {label}
      </Button>
    );
  };

  const columns: TableColumn<ClaimListItemViewModel>[] = [
    {
      id: 'selection',
      label: '',
      render: (rowData: ClaimListItemViewModel) => {
        if (rowData.isRecalculationRequired && rowData.status === 'SentToSupplier') {
          return (
            <Tooltip arrow title="Awaiting Calculation">
              <BlockRounded color="disabled" />
            </Tooltip>
          );
        }
        if (showRowSelect(rowData, roles)) {
          return (
            <Checkbox
              data-testid="selectRowCheckbox"
              color="secondary"
              checked={selectedRows.some((selectedId) => selectedId === rowData.id)}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                handleSelectionChange(rowData);
              }}
            />
          );
        }
        return null;
      },
    },
    {
      id: 'claimVendorName',
      label: 'Claim Vendor',
      minWidth: 80,
      sortable: true,
      render: (rowData: ClaimListItemViewModel, _i?: number, isFirstAdj?: boolean) =>
        isFirstAdj ? (
          <AdjustmentVendor>
            <div>Adjusted</div>
            <div>{rowData.claimVendorName}</div>
          </AdjustmentVendor>
        ) : (
          rowData.claimVendorName
        ),
    },
    {
      id: 'description',
      label: 'Rebate / Deal Description',
      minWidth: 150,
      sortable: true,
      render: (rowData: ClaimListItemViewModel) =>
        Boolean(rowData.parentClaimId) ? (
          ''
        ) : (
          <DescriptionColumn>{rowData.description}</DescriptionColumn>
        ),
    },
    {
      id: 'ownedByStaffName',
      label: 'Owner',
      minWidth: 80,
      sortable: true,
      render: (rowData: ClaimListItemViewModel) =>
        Boolean(rowData.parentClaimId) ? '' : rowData.ownedByStaffName,
    },
    {
      id: 'type',
      label: 'Type',
      render: displayClaimType,
    },
    {id: 'id', label: 'Claim ID'},
    {
      id: 'startAt',
      label: 'Claim Period Start Date',
      minWidth: 80,
      render: (rowData: ClaimListItemViewModel) => formatDate(rowData.startAt),
      sortable: true,
    },
    {
      id: 'endAt',
      label: 'Claim Period End Date',
      minWidth: 80,
      render: (rowData: ClaimListItemViewModel) => formatDate(rowData.endAt),
      sortable: true,
    },
    {
      id: 'claimFrequency',
      label: 'Claim Frequency',
      minWidth: 120,
      render: (rowData: ClaimListItemViewModel) =>
        getClaimFrequency(
          rowData.agreementType,
          rowData.claimFrequency,
          rowData.claimDaysAfterExpiry
        ),
    },
    {
      id: 'totalClaimAmount',
      label: 'Claim Amount',
      render: (
        rowData: ClaimListItemViewModel,
        _i?: number,
        _isFirstAdj?: boolean,
        totalClaimAmount?: number
      ) => {
        const postfixDifferent = rowData.totalClaimAmount !== rowData.accrualAmount ? ' *' : '';
        const postfixGstType = getGSTType(rowData);
        return (
          <TotalClaimAmountContainer>
            <Amount
              value={rowData.totalClaimAmount}
              prefix={`${postfixDifferent}$`}
              postfix={`${postfixGstType}`}
            />
            {typeof totalClaimAmount === 'number' ? (
              <TotalClaimAmount>
                Total {getDisplayAmountValue(totalClaimAmount, '$', postfixGstType, 2)}
              </TotalClaimAmount>
            ) : null}
          </TotalClaimAmountContainer>
        );
      },
    },
    {
      id: 'relatedId',
      label: 'Deal/Rebate ID',
      render: displayRelatedId,
    },
    {
      id: 'status',
      label: 'Status',
      render: (rowData: ClaimListItemViewModel) => <ClaimStatus status={rowData.status} />,
      sortable: true,
    },
    {
      id: 'action',
      render: renderActionColumn,
    },
    {
      id: 'promotionIdAndSupplierNumber',
      additionalLine: 1,
      colspanLeft: 2,
      colspan: 8,
      isShown: (rowData: ClaimListItemViewModel) =>
        !!rowData.promotionId || !!rowData.supplierApprovalNumber,
      render: (rowData: ClaimListItemViewModel) => (
        <div>
          {rowData.promotionId ? <div>{`Promo ID: ${rowData.promotionId}`}</div> : null}
          {rowData.supplierApprovalNumber ? (
            <div>{`Supplier Approval Number: ${rowData.supplierApprovalNumber}`}</div>
          ) : null}
        </div>
      ),
      style: {
        fontStyle: 'italic',
        opacity: 0.7,
        paddingTop: 0,
      },
    },
    {
      id: 'rollbackStatus',
      additionalLine: 1,
      colspan: 3,
      isShown: () => true,
      render: (rowData: ClaimListItemViewModel) =>
        rowData.agreementType === Consts.AgreementTypeEnum.Deal &&
        rowData.status === Consts.ClaimStatusEnum.SentToSupplier ? (
          <RollbackStatus
            claim={rowData}
            onSuccess={() =>
              handleFilterByStatusChange(undefined, Consts.ClaimStatusEnum.SentToSupplier)
            }
          />
        ) : null,
      style: {
        paddingTop: 0,
        margin: '0 auto',
      },
    },
    {
      id: 'expandedview',
      additionalLine: 2,
      additionalLineMode: AdditionalLineMode.Standalone,
      colspan: 13,
      isShown: (rowData: ClaimListItemViewModel) => !!expandedClaims[rowData.id],
      render: (rowData: ClaimListItemViewModel) => {
        return (
          <ClaimExpandedView
            status={rowData.status}
            id={rowData.id}
            onClaimUpdated={updateClaimRowData}
          />
        );
      },
    },
  ];

  /**
   * data from expanded view passed to this function comes from claim details api
   * mapping that field approvalNumber to supplierApprovalNumber from claim list api
   */
  const updateClaimRowData = ({
    id,
    totalClaimAmount,
    claimTaxAmount,
    approvalNumber,
  }: ClaimListItemViewModel & {approvalNumber: string}) => {
    setRows((prevData) => {
      const newData = prevData.map((row) =>
        row.id === id
          ? {
              ...row,
              ...(typeof claimTaxAmount === 'number' && {claimTaxAmount}),
              ...(typeof totalClaimAmount === 'number' && {totalClaimAmount}),
              ...(approvalNumber && {supplierApprovalNumber: approvalNumber}),
            }
          : row
      );
      return newData;
    });
  };

  const handleSelectionChange = (rowData: ClaimListItemViewModel) => {
    setSelectedRows((prevSelectedRows: number[]) => {
      const arrIndex = prevSelectedRows.indexOf(rowData.id);
      if (arrIndex < 0) {
        return [...prevSelectedRows, rowData.id];
      } else {
        const newSelection = [...prevSelectedRows];
        newSelection.splice(arrIndex, 1);
        return newSelection;
      }
    });
  };

  const handleFilterByStatusChange = (_: any, newStatus: any) => {
    setFilterSelection({...filterSelection, status: newStatus});
    setPagination({currentPage: 1});
    const request = mapGetClaimsParams(
      {
        searchText,
        ...pagination,
        ...filterSelection,
        ...order,
        status: newStatus,
        currentPage: 1,
      },
      my ? loggedInStaffCode : undefined
    );
    getClaims(request);
  };

  const handleSearch = (newSearchText: string) => {
    setSearchText(newSearchText);
    setPagination({currentPage: 1});
    const request = mapGetClaimsParams(
      {
        searchText: newSearchText,
        ...pagination,
        ...filterSelection,
        ...order,
        currentPage: 1,
      },
      my ? loggedInStaffCode : undefined
    );
    getClaims(request);
  };

  const handlePagination = (newPagination: Partial<Pagination>) => {
    setPagination(newPagination);

    const request = mapGetClaimsParams(
      {
        searchText,
        ...pagination,
        ...filterSelection,
        ...newPagination,
        ...order,
      },
      my ? loggedInStaffCode : undefined
    );
    getClaims(request);
  };

  const handleUpdateFilters = (selection: ClaimSideFilters) => {
    setFilterSelection(selection);
    const request = mapGetClaimsParams(
      {
        searchText,
        ...pagination,
        currentPage: 1,
        ...filterSelection,
        ...selection,
        ...order,
      },
      my ? loggedInStaffCode : undefined
    );
    getClaims(request);
  };

  const handleOrderBy = (newOrderBy: string) => {
    setIsDefaultOrder(false);
    const newOrder = createOrderFromParams(newOrderBy, setOrder);
    const request = mapGetClaimsParams(
      {
        searchText,
        ...pagination,
        ...filterSelection,
        ...(newOrder ? newOrder : {}),
      },
      my ? loggedInStaffCode : undefined
    );
    getClaims(request, true);
  };

  const handleRefreshData = () => {
    setSelectedRows([]);
    const request = mapGetClaimsParams(
      {
        searchText,
        ...pagination,
        ...filterSelection,
        ...order,
      },
      my ? loggedInStaffCode : undefined
    );
    getClaims(request);
  };

  const toggleExpandedView = (id: any) => {
    setExpandedClaims((preClaims: any) => {
      const newClaims = {...preClaims, [id]: !preClaims[id]};
      return newClaims;
    });
  };

  const toggleSideFilters = () => {
    if (!openSideFilters) {
      filterRef?.current?.scrollIntoView?.({block: 'start', behavior: 'smooth'});
    }
    setOpenSideFilters((open) => !open);
  };

  const handleBulkUploadComplete = (response?: AxiosResponse<ClaimBulkUploadResponse>) => {
    const uploadCount = response?.data?.totalClaimsUploaded ?? 0;
    setBulkUploadSuccessModal({show: true, count: uploadCount});
    handleRefreshData();
  };

  const handleCloseBulkUploadSuccessModal = () => {
    setBulkUploadSuccessModal({show: false, count: 0});
  };

  const filterCount = useMemo(() => {
    const {
      departments,
      financeAccounts,
      status,
      types,
      ...selectionWithoutDepartmentsAndFinanceAccounts
    } = filterSelection;

    const departmentFilterCount = departments && departments.length > 0 ? 1 : 0;
    const financeAccountsFilterCount = financeAccounts && financeAccounts.length > 0 ? 1 : 0;
    const typesFilterCount = types && types.length > 0 ? 1 : 0;

    let otherFilterCount = 0;
    if (my) {
      const {owner, ...selectionWithoutOwners} = selectionWithoutDepartmentsAndFinanceAccounts;
      otherFilterCount = Object.values(selectionWithoutOwners).filter((x) => x).length;
    } else {
      otherFilterCount = Object.values(selectionWithoutDepartmentsAndFinanceAccounts).filter(
        (x) => x
      ).length;
    }
    return otherFilterCount + departmentFilterCount + financeAccountsFilterCount + typesFilterCount;
  }, [filterSelection, my]);

  const status = Consts.ClaimFilterStatus(my).find((x) => x.value === filterSelection?.status);
  const statusTitle = status?.label ?? '';
  const statusValue = status?.value ?? null;

  return (
    <Root className={classes.root} ref={filterRef}>
      <PageTabs
        title={statusTitle}
        value={statusValue}
        onChange={handleFilterByStatusChange}
        tabsList={Consts.ClaimFilterStatus(my)}
        ariaLabel="Filter claims by status"
      />
      <Grid container wrap="nowrap">
        {openSideFilters ? (
          <Grid item>
            <ClaimSideFilter
              my={my}
              defaultSelection={filterSelection}
              onClose={() => setOpenSideFilters(false)}
              onChange={handleUpdateFilters}
            />
          </Grid>
        ) : null}

        <Grid item sx={{width: '100%'}}>
          <Container
            maxWidth="lg"
            classes={{
              root: classes.containerRoot,
              maxWidthLg: classes.containerWidthLg,
            }}
          >
            <ListFilterContainer>
              <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                <FiltersIndicator count={filterCount} onClick={toggleSideFilters} />
                <SearchInputField
                  placeholder="Search claims"
                  width="26rem"
                  onSearch={handleSearch}
                  defaultValue={searchText ?? ''}
                />
              </Stack>
              <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                <DownloadClaimsCsvButton
                  filters={filterSelection}
                  searchText={searchText}
                  order={order}
                  ownerStaffCodeOverride={my ? loggedInStaffCode : undefined}
                />
                <BulkUploadRebateClaims onUploadComplete={handleBulkUploadComplete} />
                <RequireAuth
                  requiredRoles={{
                    all: [Consts.UserRoleEnum.ManageContractAgreementClaims],
                  }}
                  alt={null}
                >
                  <AdvanceButton
                    variant="contained"
                    disabled={selectedFinalisedClaims.length === 0}
                    onClick={() => {
                      setOpenReviewModal(true);
                    }}
                  >
                    ({selectedFinalisedClaims.length}) Review Invoice(s)
                  </AdvanceButton>
                </RequireAuth>
                <RequireAuth
                  requiredRoles={{
                    any: [
                      Consts.UserRoleEnum.ManageDealClaims,
                      Consts.UserRoleEnum.ManageContractAgreementClaims,
                    ],
                  }}
                  alt={null}
                >
                  <AdvanceButton
                    variant="contained"
                    disabled={selectedRows.length === 0}
                    onClick={() => {
                      setOpenAdvanceModal(true);
                    }}
                  >
                    ({selectedRows.length}) Advance Status
                  </AdvanceButton>
                </RequireAuth>
              </Stack>
            </ListFilterContainer>
            <TableTabPanel loading={loading} className={classes.tabPanelRoot}>
              <SimpleDataTable
                columns={columns}
                rows={rows}
                pagination={pagination}
                onChangePage={handlePagination}
                onChangePageSize={handlePagination}
                onOrderBy={(o) => handleOrderBy(o.orderBy)}
                sortOrderBy={order.orderBy}
                sortOrder={order.orderByDirection}
                onRowClick={toggleExpandedView}
                highlightColumn={order.orderBy}
              />
            </TableTabPanel>
            <ClaimAdvanceModal
              claims={rows.filter((x: any) => selectedRows.indexOf(x.id) >= 0)}
              open={openAdvanceModal}
              onCancel={() => setOpenAdvanceModal(false)}
              onOk={() => {
                setOpenAdvanceModal(false);
                handleRefreshData();
              }}
            />
            <InvoiceReviewModal
              claims={rows.filter((x: any) => selectedFinalisedClaims.indexOf(x.id) >= 0)}
              open={openReviewModal}
              onCancel={() => setOpenReviewModal(false)}
              onOk={() => {
                setOpenReviewModal(false);
                setOpenAdvanceModal(true);
              }}
            />
            <BulkUploadCompleteModal
              open={bulkUploadSuccessModal.show}
              count={bulkUploadSuccessModal.count}
              onOk={handleCloseBulkUploadSuccessModal}
            />
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
};

export default ClaimListing;
