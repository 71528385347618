import {TableCellProps} from '@mui/material/TableCell';
import {TableSortLabelOwnProps} from '@mui/material/TableSortLabel';
export type GSTType = 'Free' | 'Inclusive' | 'Exclusive';
export type EntityCode = 'JBAU' | 'JBNZ' | 'TGGAU';
export type AmountType = 'Percentage' | 'FixedAmount' | 'ValuePerUnit';
export type RebateType = 'Sales' | 'Purchases' | 'FixedAmount' | 'PriceProtection';
export type WritebackType = 'Scanbacks' | 'BuyInDealsAndDisplayAllowance';
export type FinanceAccount = {
  amountGst?: number;
  amountInclusiveGst?: number;
  name?: string;
};
export type Facet = {
  facetType?: string;
  name?: string;
  values?: string[];
};
export type QueryCriteria = {
  indexName?: string;
  facetInclusions: Facet[];
  facetExclusions: Facet[];
  filters?: string;
  resultCount?: number;
};
export type AttachmentType = {
  id: string;
  fileName: string;
  fileType?: string;
  fileSize?: number;
  fileUrl?: string;
  uploadedBy?: string;
  uploadedOn?: string;
  parentId?: number;
};
export type Config = {
  DealLockDate: string;
  DealCutoffDate: string;
  TaxDistributionSplitAccount: string;
  PayablesAmountSplitAccount: string;
  SpivBalanceSheetSplitAccountId: string;
  SpivOverAndAboveSplitAccountId: string;
  SpivTemporarySplitAccountId: string;
  SpivWrittenAmountPercentage: string;
};
export interface Department {
  number: number;
  description: string;
  entityCode: string;
}
export type Profile = {
  buyerDepartments?: Department[] | null;
  companyName?: string | null;
  country?: string | null;
  department?: string;
  displayName?: string;
  employeeId?: string;
  id?: string;
  jobTitle?: string | null;
  mail?: string;
  officeLocation?: string | null;
  onPremisesExtensionAttributes?: Record<string, string | null>;
  roles?: string[];
  staffCode: number;
  usageLocation?: string;
  userPrincipalName?: string;
};

export type Pagination = {
  totalCount: number;
  totalPages: number;
  pageSize: number;
  currentPage: number;
};

export type PaginatedDataResponse<T> = {
  data: T;
} & Pagination;

export type SplitAccount = {
  accountId: number;
  accountName?: string;
  amount: number;
  additionalInfo: string | null;
  hasAdditionalField?: boolean;
  additionalFieldName?: string | null;
};

// formik table errors
export type TError = {[x: string]: {touched: boolean; error?: string}};
export type TableErrors = {[x: number]: TError};

// can be used to type initial (invalid) form values
export type RecursiveUndefined<T> = T extends object
  ? {
      [K in keyof T]: RecursiveUndefined<T[K]> | undefined;
    }
  : T | undefined;

export type TableColumn<T = any> = {
  additionalLine?: number; // line number used to add new row for each unique number eg 1 for approval number, 2 for expanded view
  additionalLineMode?: AdditionalLineMode;
  align?: TableCellProps['align'];
  cellOnClick?: (rowData: T, index: number, ctrlKeyPressed: boolean) => void;
  colspan?: number;
  colspanLeft?: number;
  colspanRight?: number;
  editStyle?: React.CSSProperties;
  field?: keyof T | string | null;
  hide?: boolean;
  id: string;
  isAction?: boolean;
  isShown?: (rowData: T) => boolean;
  label?: React.ReactNode;
  minWidth?: React.CSSProperties['minWidth'];
  render?: (rowData: T, index: number, isFirst?: boolean, amount?: number) => React.ReactNode;
  renderEdit?: (rowData: T, index: number) => React.ReactNode;
  sortable?: boolean;
  style?: React.CSSProperties;
  title?: string | React.ReactNode;
};

export type Supplier = {
  name: string;
  number: number;
  entityCode: EntityCode;
};

export type SupplierResponse = {
  data: Supplier[];
} & Pagination;

export type AccountOption = {
  id: number;
  name: string;
  isDefault: boolean;
  hasAdditionalField: boolean;
  additionalFieldName: string | null;
};

export type Product = {
  productCode: string;
  description: string;
  suppliers: number[];
};

export type SelectOption = {
  label: string;
  value: string;
};
export type Order = {
  orderBy: string;
  orderByDirection: TableSortLabelOwnProps['direction'];
};

export enum EntityActionType {
  Deal = 'deal',
  ContractAgreement = 'contract agreement',
  Rebate = 'rebate',
  MixAndMatch = 'mix and match',
  SPIV = 'SPIV',
}

export enum AdditionalLineMode {
  Standalone = 'Standalone',
  Inherit = 'Inherit',
}

// map resource ids to error fields and message
export type ErrorMap<T extends string = string> = Record<number, {[K in T]: string}>;

export type ListPageParamOptions<T> = Pagination & T & {searchText: string | null} & Partial<Order>;

// Utility type to enforce one or all keys constraint with a generic value type
export type AtLeastOne<T, V> = {
  [K in keyof T]: {[P in K]: V};
}[keyof T] &
  Partial<Record<keyof T, V>>;
